<template>
  <Dialog :visible="containerVisible" :style="{width: '600px'}" :header="$t('shipment.dialog.form_header')" :modal="true" class="p-fluid" @update:visible="updateVisible">
    <div class="field grid">
      <label for="departure" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('shipment.departure')}}</label>
      <FormField :message="errors.departure" class="col-12 md:col-10">
        <Calendar v-model="currentEntity.departure" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" :required="true" :disabled="currentEntity.status!='inited'"/>
      </FormField>
    </div>
    <div class="field grid">
      <label for="awbCode" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('shipment.awbCode')}}</label>
      <form-field :message="errors.awbCode" class="col-12 md:col-10">
        <InputText ref="awbCode" id="awbCode" v-model.trim="currentEntity.awbCode"  autofocus :required="true" :disabled="currentEntity.status!='inited'"/>
      </form-field>
    </div>
    <div class="field grid">
      <label for="weightLbs" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('shipment.weightLbs')}}</label>
      <form-field :message="errors.weightLbs" class="col-12 md:col-10">
        <InputNumber ref="weightLbs" id="weightLbs" v-model.trim="currentEntity.weightLbs" :required="true" autofocus :disabled="currentEntity.status!='inited'"/>
      </form-field>
    </div>
    <div class="field grid">
      <label for="note" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('shipment.note')}}</label>
      <div class="col-12 md:col-10">
        <Textarea id="note" v-model="currentEntity.note" required="false" rows="5" cols="20" />
      </div>
    </div>
    <template #footer>
      <Button :label="$t('button.cancel')" icon="pi pi-times" class="p-button-text" @click="onCancel"/>
      <Button :label="$t('button.save')" icon="pi pi-check" class="p-button-text" @click="onSave" />
    </template>
  </Dialog>
</template>

<script>
//import AutoCompleteValue from '@/components/form/AutoCompleteValue'
import FormField from '@/components/FormField'
import form from '@/mixins/form'
//import { useForm, useField } from 'vee-validate';
export default {
  components: {FormField},
  name:'form-partner',
  mixins: [form],
  props: {
    entity: {
      type: Object,
      default() {
        // default function receives the raw props object as argument
        return {  }
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits:['cancel','save','hide'],
  data() {
    return {
      submitted: false,
      currentEntity: this.entity,
      containerVisible: this.visible,
      errors: {},
    }
  },
  methods: {
    onChangePartnerType() {
      //this.currentEntity.partnerType = event.value.value;
    },
    onCancel() {
      this.containerVisible = false;
      this.currentEntity = {};
      this.$emit('cancel');
    },
    onSave() {
      if(!this.submitted) {
        this.errors = this.validate(this.currentEntity, 'shipment');
        if(Object.keys(this.errors).length == 0) {
          this.submitted = true;
          this.$emit('save', this.currentEntity);
        }
      }
    },
    updateVisible(visible) {
      this.containerVisible = visible;
      this.$emit('hide')
    }
  },
  mounted() {
    
  },
  updated() {
    this.currentEntity = this.entity;
    this.containerVisible = this.visible;
  },
}
</script>
