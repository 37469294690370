<template>
  <Dialog :visible="visible" :style="{width: '345px'}" 
    :header="$t('shipment.dialog.qrcode_header')" :modal="true" class="p-fluid" 
    @update:visible="updateVisible">
    <qrcode-vue :value="shipmentURI" :size="qrcodeSize" level="H" />
    <!-- <template #footer>
      <Button :label="$t('button.cancel')" icon="pi pi-times" class="p-button-text" @click="onCancel"/>
    </template> -->
  </Dialog>
</template>

<script>
export default {
  name:'dialog-shipment-qrcode',
  props: {
    shipmentId: {
      type: Number,
      default: 0
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits:['cancel', 'hide'],
  data() {
    return {
      qrcodeSize: 300,
    }
  },
  computed: {
    shipmentURI() {
      const routeData = this.$router.resolve({ name: 'agent_package_scanner', params: {id: this.shipmentId} });
      let uri = `${process.env.VUE_APP_BASE_URL}/${routeData.href}`;
      //console.log(uri);
      return uri;
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    updateVisible(visible) {
      if (!visible) {
        this.$emit('hide');
      }
    }
  },
  mounted() {
    
  },
}
</script>
